import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex'}}>
          <div className={classes.section}>
            <div className={classes.container}>
              <div id="typography">
                <div className={classes.title}>
                </div>
                <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
                  <h2>Kontakt</h2>
                  <h3>ks. Artur Kasprzycki</h3>
                  <h3>Kuria diecezjalna w Opolu (Odnowa w Duchu Świętym)</h3>
                  <h3>ul. Książąt Opolskich 19</h3>
                  <h3>45-005 Opole</h3>
                  <h3>Email: sekretariat@opole.odnowa.org</h3>
                  <h3>Numer konta: 51 1240 5178 1111 0010 8790 3088</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
